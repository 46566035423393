import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { navigate } from 'gatsby';
import classNames from 'classnames';

import useScreenRecognition from 'hooks/useScreenRecognition';
import CategoryFilters from 'components/AlgoliaFilters/CategoryFilters';
import ChosenFiltersList from 'components/AlgoliaFilters/ChosenFiltersList';
import ChosenFiltersTitle from 'components/AlgoliaFilters/ChosenFiltersTitle';
import FiltersToggler from 'components/AlgoliaFilters/FiltersToggler';
import FilterPanel from 'components/AlgoliaFilters/FilterPanel';
import ConnectedCustomHits from 'components/AlgoliaFilters/CustomHits';
import ConnectedCategoryFilters from 'components/AlgoliaFilters/ConnectedCategoryFilters';

import './AlgoliaFilters.scss';
import { IPropsAlgoliaFilters } from './model';
import Helpers from './helpers';
import {
  ALGOLIA_FILTERS_IDS,
  DefaultSearchState,
  HitsPerPage,
  FILTER_OPERATOR_OR,
} from './constants';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY as string
);

const AlgoliaFilters: FC<IPropsAlgoliaFilters> = ({
  indexName,
  filterSections,
  checkboxStyles,
  customGlobalStyles,
  filterButtonsTexts,
  categories,
  currentPageId,
  masterPageId,
  masterPageLink,
  idInMainDOM,
  showResultsBtn,
  clearAllBtn,
  filterMenuBgColor,
  categorySectionTitle,
  saveAlgoliaHitsResponse,
  handleAlgoliaFiltersUsed,
  handleActiveListPage,
  handleHitsResponseActivated,
  isHitsResponseActivated,
  lang,
  additionalFilterParams,
  categoryFacetName,
  showFilters,
  setFilters,
  isSmallDevice,
  removeAppliedFilterAriaLabel,
  isFixedPosition,
  filtersTitle,
  refineCategories,
}): ReactElement | null => {
  const { isLargeDesktop } = useScreenRecognition();
  const isFixedFilterVariant = isFixedPosition && isLargeDesktop;

  const categoryFiltersClasses = classNames('nf-filter-navigation__active-filters-holder', {
    'nf-filter-navigation__fixed-position': isFixedFilterVariant,
  });

  const [activeCategoryId, setActiveCategoryId] = useState<number | null>(null);
  const [searchState, setSearchState] = useState<AppFilters.IAlgoliaSearchState>(
    DefaultSearchState
  );
  const [preferred, setPreferred] = useState<AppFilters.IAlgoliaSearchState['optionalFilters']>('');
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const { chosenFilterItems, chosenFilterIds } = useMemo(
    () => Helpers.getChosenFilterItems(filterSections, searchState.refinementList),
    [filterSections, searchState.refinementList]
  );

  const activeCategoryFiltersCount = activeCategoryId ? 1 : 0;
  const filtersNumber = showFilters
    ? chosenFilterItems.length + (activeCategoryId ? 1 : 0)
    : activeCategoryFiltersCount;

  const masterPageData = { id: masterPageId, link: masterPageLink };

  const handleActiveCategoryIdOnLoad = useCallback(() => {
    const pageCategoryId = Helpers.getActiveCategoryIdToSet(categories, currentPageId);

    if (!activeCategoryId && pageCategoryId) {
      setActiveCategoryId(pageCategoryId);
    }
  }, []);

  const handleActiveFiltersIdsOnLoad = useCallback(() => {
    const newSearchState = Helpers.getSavedSelectionDataToSet();
    if (!newSearchState) {
      return;
    }
    handleAlgoliaFiltersUsed(true);
    setSearchState(newSearchState);
  }, []);

  useEffect(() => {
    if (!filterSections) {
      return;
    }
    handleActiveFiltersIdsOnLoad();
    handleActiveCategoryIdOnLoad();
  }, [filterSections]);

  const checkAlgoliaFiltersUsage = useCallback((newSearchState: AppFilters.IAlgoliaSearchState) => {
    if (!newSearchState?.refinementList) {
      handleAlgoliaFiltersUsed(false);

      return;
    }

    if (Object.keys(newSearchState.refinementList).length) {
      handleAlgoliaFiltersUsed(true);

      return;
    }

    handleAlgoliaFiltersUsed(false);
  }, []);

  const handleOnSearchStateChange = useCallback(
    (newSearchState: AppFilters.IAlgoliaSearchState) => {
      if (newSearchState.refinementList) {
        const preferredItems = Helpers.getOptionalFiltersParams(newSearchState.refinementList);
        setPreferred(preferredItems);
      }

      Helpers.setFilteredUrlParams(filterSections, newSearchState.refinementList);
      checkAlgoliaFiltersUsage(newSearchState);
      setSearchState(newSearchState);
      // Switch to the main page on every filters changing
      handleActiveListPage(0);
    },
    [activeCategoryId]
  );

  const handleRemoveSelectionData = useCallback(
    (sectionId: string, itemId: number) => () => {
      setSearchState((oldState: AppFilters.IAlgoliaSearchState) => {
        const newSearchState = {
          ...oldState,
          refinementList: {
            ...oldState.refinementList,
          },
        };

        const sectionData = Helpers.removeItemInRefinementList(
          oldState?.refinementList?.[sectionId],
          String(itemId)
        );

        if (!sectionData) {
          delete newSearchState.refinementList[sectionId];
        } else {
          newSearchState.refinementList[sectionId] = sectionData;
        }

        if (newSearchState.refinementList) {
          const preferredItems = Helpers.getOptionalFiltersParams(newSearchState.refinementList);
          setPreferred(preferredItems);
        }

        Helpers.setFilteredUrlParams(filterSections, newSearchState.refinementList);

        return newSearchState;
      });

      // Switch to the main page on every filters changing
      handleActiveListPage(0);
    },
    []
  );

  const handleSidebarOpen = useCallback(() => {
    setSidebarOpen((oldValue: boolean) => !oldValue);
  }, []);

  const handleResetSelectionData = useCallback(() => {
    if (chosenFilterItems.length) {
      setSearchState({ ...DefaultSearchState });
    }
    handleSidebarOpen();
    navigate(masterPageData.link);
  }, [chosenFilterItems.length]);

  useEffect(() => {
    setFilters(chosenFilterItems);
  }, [chosenFilterItems]);

  return searchClient ? (
    <div data-test="AlgoliaFilters" className="algolia-filters">
      <InstantSearch
        indexName={indexName}
        searchClient={searchClient}
        refresh
        stalledSearchDelay={500}
        searchState={searchState}
        onSearchStateChange={handleOnSearchStateChange}
      >
        <Configure
          filters={Helpers.getDefaultFiltersParams(
            categoryFacetName,
            activeCategoryId,
            lang,
            additionalFilterParams
          )}
          hitsPerPage={HitsPerPage}
          analytics={false}
          distinct
          optionalFilters={preferred}
          maxValuesPerFacet={HitsPerPage}
        />

        <Container fluid>
          <Row className="algolia-filters__controls-holder">
            <Col lg={3} md={0}>
              {isSmallDevice || !showFilters || isFixedFilterVariant ? null : (
                <ChosenFiltersTitle
                  isActive={Boolean(chosenFilterItems.length)}
                  filtersSelectLabel={filterButtonsTexts.filtersSelectLabel}
                  filtersHaveSelectedLabel={filterButtonsTexts.filtersHaveSelectedLabel}
                />
              )}
            </Col>
            <Col lg={6} md={0}>
              {isSmallDevice ? null : categories?.length ? (
                <div className="nf-category-filters--desktop">
                  {refineCategories ? (
                    <ConnectedCategoryFilters
                      attribute={categoryFacetName}
                      operator={FILTER_OPERATOR_OR}
                      categories={categories}
                      activeCategoryId={activeCategoryId}
                      customGlobalStyles={customGlobalStyles}
                      masterPageData={masterPageData}
                      isMobile={isSmallDevice}
                    />
                  ) : (
                    <CategoryFilters
                      categories={categories}
                      activeCategoryId={activeCategoryId}
                      customGlobalStyles={customGlobalStyles}
                      masterPageData={masterPageData}
                      isMobile={isSmallDevice}
                    />
                  )}
                </div>
              ) : null}
            </Col>
            {(isSmallDevice || showFilters) && !isFixedFilterVariant ? (
              <Col lg={3} md={12}>
                <FiltersToggler
                  id={ALGOLIA_FILTERS_IDS.showResultsScrollTarget}
                  handleSidebarOpen={handleSidebarOpen}
                  filtersNumber={filtersNumber}
                  customGlobalStyles={customGlobalStyles}
                  isMobile={isSmallDevice}
                  filtersTogglerButtonText={filterButtonsTexts.filtersTogglerButtonText}
                />
              </Col>
            ) : null}
          </Row>
          {!showFilters ? null : (
            <Row className={categoryFiltersClasses}>
              <Col>
                <ChosenFiltersList
                  items={chosenFilterItems}
                  handleRemoveSelectionData={handleRemoveSelectionData}
                  customGlobalStyles={customGlobalStyles}
                  removeAppliedFilterAriaLabel={removeAppliedFilterAriaLabel}
                />
              </Col>
            </Row>
          )}
        </Container>
        <FilterPanel
          filterSections={filterSections}
          checkboxStyles={checkboxStyles}
          customGlobalStyles={customGlobalStyles}
          filterButtonsTexts={filterButtonsTexts}
          showResultsScrollTargetId={ALGOLIA_FILTERS_IDS.showResultsScrollTarget}
          isSidebarOpen={isSidebarOpen}
          handleSidebarOpen={handleSidebarOpen}
          idInMainDOM={idInMainDOM}
          isMobile={isSmallDevice}
          showResultsBtn={showResultsBtn}
          clearAllBtn={clearAllBtn}
          filterMenuBgColor={filterMenuBgColor}
          handleResetSelectionData={handleResetSelectionData}
          categories={categories}
          activeCategoryId={activeCategoryId}
          masterPageData={masterPageData}
          categorySectionTitle={categorySectionTitle}
          chosenFilterIds={chosenFilterIds}
          handleRemoveSelectionData={handleRemoveSelectionData}
          showFilters={showFilters}
          isFixedFilters={isFixedFilterVariant}
          filtersTitle={filtersTitle}
          categoryFacetName={categoryFacetName}
          refineCategories={refineCategories}
        />

        <ConnectedCustomHits
          saveAlgoliaHitsResponse={saveAlgoliaHitsResponse}
          handleHitsResponseActivated={handleHitsResponseActivated}
          isHitsResponseActivated={isHitsResponseActivated}
        />
      </InstantSearch>
    </div>
  ) : null;
};

export default AlgoliaFilters;
