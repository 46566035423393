import React, { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import useClickOutside from 'hooks/useClickOutside';
import { scrollToTarget } from 'utils/browser';

import IconCustom from 'components/common/IconCustom';

import CategorySection from 'components/AlgoliaFilters/CategorySection';
import CategoryFilters from 'components/AlgoliaFilters/CategoryFilters';
import FilterSection from 'components/AlgoliaFilters/FilterSection';
import ModuleHelpers from 'components/AlgoliaFilters/moduleHelpers';
import ConnectedCategoryFilters from 'components/AlgoliaFilters/ConnectedCategoryFilters';
import { FILTER_OPERATOR_OR } from 'components/AlgoliaFilters/constants';

import './FilterPanel.scss';
import { IPropsFilterPanel, IStylesData } from './model';
import Helpers from './helpers';

const FilterPanel: FC<IPropsFilterPanel> = ({
  filterSections,
  checkboxStyles,
  customGlobalStyles,
  filterButtonsTexts,
  isSidebarOpen,
  handleSidebarOpen,
  idInMainDOM,
  isMobile,
  showResultsScrollTargetId,
  showResultsBtn,
  clearAllBtn,
  filterMenuBgColor,
  handleResetSelectionData,
  masterPageData,
  categorySectionTitle,
  categories,
  activeCategoryId,
  chosenFilterIds,
  handleRemoveSelectionData,
  showFilters,
  isFixedFilters,
  filtersTitle,
  categoryFacetName,
  refineCategories,
}): ReactElement | null => {
  const wrapperRef = useRef(null);

  const [stylesData, setStylesData] = useState<IStylesData>({
    top: '0px',
    height: '100vh',
    width: '',
  });

  const handleCloseSidebar = useCallback(() => {
    if (!isSidebarOpen) {
      return;
    }
    handleSidebarOpen();
  }, [isSidebarOpen]);

  const handleStylesOnLoad = useCallback(
    (isMobileStyles: boolean) => () => {
      const data = isMobileStyles
        ? Helpers.getMobileStylesData()
        : Helpers.getDesktopStylesData(idInMainDOM);
      if (!data) {
        return;
      }
      setStylesData(data);
    },
    []
  );

  useClickOutside(wrapperRef, handleCloseSidebar);

  useEffect(() => {
    if (isMobile === null) {
      return;
    }

    handleStylesOnLoad(isMobile)();
  }, [isMobile]);

  const handleShowResults = useCallback(() => {
    handleSidebarOpen();
    scrollToTarget(showResultsScrollTargetId, 40);
  }, []);

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLUListElement | any>) => {
      if (!event.currentTarget.contains(event.relatedTarget)) {
        handleCloseSidebar();
      }
    },
    [handleCloseSidebar]
  );

  const {
    defaultTextColorClass: defaultTextColorClassShowResultsBtn,
    defaultBgColorClass: defaultBgColorClassShowResultsBtn,
  } = ModuleHelpers.getCustomClassNames(customGlobalStyles, {
    defaultControlsBgColor: showResultsBtn?.defaultBgColor,
    defaultControlsTextColor: showResultsBtn?.defaultTextColor,
  });
  const showResultsBtnClasses = classNames('show-results-btn', {
    [defaultTextColorClassShowResultsBtn || '']: defaultTextColorClassShowResultsBtn,
    [defaultBgColorClassShowResultsBtn || '']: defaultBgColorClassShowResultsBtn,
  });

  const {
    defaultTextColorClass: defaultTextColorClassClearAllBtn,
    defaultBgColorClass: defaultBgColorClassClearAllBtn,
  } = ModuleHelpers.getCustomClassNames(null, {
    defaultControlsBgColor: clearAllBtn?.defaultBgColor,
    defaultControlsTextColor: clearAllBtn?.defaultTextColor,
  });
  const clearAllBtnClasses = classNames('clear-btn', {
    [defaultTextColorClassClearAllBtn || '']: defaultTextColorClassClearAllBtn,
    [defaultBgColorClassClearAllBtn || '']: defaultBgColorClassClearAllBtn,
  });

  const { defaultBgColorClass: defaultBgColorClassSidebar } = ModuleHelpers.getCustomClassNames({
    defaultControlsBgColor: filterMenuBgColor || undefined,
  });
  const menuClasses = classNames('menu', {
    [defaultBgColorClassSidebar || '']: defaultBgColorClassSidebar,
  });

  const filtersStateClasses = classNames('filter-panel', {
    open: isSidebarOpen && !isFixedFilters,
    fixedPosition: isFixedFilters,
  });

  const slideClasses = classNames({ 'filter-panel__slide': !isFixedFilters });

  return isMobile !== null ? (
    <div
      data-test="FilterPanel"
      // eslint-disable-next-line jsx-a11y/tabindex-no-positive
      tabIndex={1}
      role="button"
      onBlur={handleOnBlur}
      className={filtersStateClasses}
      style={
        isMobile
          ? {
              top: stylesData.top,
              height: stylesData.height,
              width: stylesData.width ? `calc(${stylesData.width} - 5%)` : 0,
              marginRight: stylesData.width ? `calc(-${stylesData.width} + 5%)` : '-100%',
            }
          : { maxHeight: stylesData.height }
      }
    >
      <div className={slideClasses}>
        <div className="cover" />
        <div className={menuClasses} ref={wrapperRef}>
          {!isFixedFilters ? (
            <div className="filter-panel-header">
              <button className="close-btn" type="button" onClick={handleSidebarOpen}>
                <IconCustom icon="close_icon-2" />
                <span className="close-btn-title">{filterButtonsTexts?.closeButtonText}</span>
              </button>
            </div>
          ) : null}
          {isFixedFilters && filtersTitle ? (
            <p className="filter-panel--title">{filtersTitle}</p>
          ) : null}
          <div className="content">
            {isMobile && categories?.length ? (
              <CategorySection
                header={categorySectionTitle}
                content={
                  refineCategories ? (
                    <ConnectedCategoryFilters
                      attribute={categoryFacetName}
                      operator={FILTER_OPERATOR_OR}
                      categories={categories}
                      activeCategoryId={activeCategoryId}
                      masterPageData={masterPageData}
                      customGlobalStyles={customGlobalStyles}
                      isMobile={isMobile}
                    />
                  ) : (
                    <CategoryFilters
                      categories={categories}
                      activeCategoryId={activeCategoryId}
                      masterPageData={masterPageData}
                      customGlobalStyles={customGlobalStyles}
                      isMobile={isMobile}
                    />
                  )
                }
              />
            ) : null}
            {filterButtonsTexts &&
            checkboxStyles &&
            handleRemoveSelectionData &&
            chosenFilterIds &&
            filterSections &&
            filterSections?.length > 0
              ? filterSections.map((section: AppFilters.IFilterSection) => (
                  <FilterSection
                    key={section.header}
                    title={section.header}
                    attribute={section.attributeForFaceting}
                    filterItems={section.filterItems}
                    variant={section.variant}
                    isOpen={section.isOpen}
                    checkboxStyles={checkboxStyles}
                    customGlobalStyles={customGlobalStyles}
                    filterButtonsTexts={filterButtonsTexts}
                    operator={section.operand}
                    chosenFilterIds={chosenFilterIds}
                    handleRemoveSelectionData={handleRemoveSelectionData}
                    showFilters={showFilters}
                  />
                ))
              : null}
          </div>
          {showResultsBtn && clearAllBtn ? (
            <div className="footer">
              {isMobile ? (
                <button className={showResultsBtnClasses} type="button" onClick={handleShowResults}>
                  {showResultsBtn.title}
                </button>
              ) : null}

              <button
                className={clearAllBtnClasses}
                type="button"
                onClick={handleResetSelectionData}
              >
                {clearAllBtn.title}
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
};

export default FilterPanel;
