import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import classNames from 'classnames';
import { navigate } from 'gatsby';

import IconCustom from 'components/common/IconCustom';
import RadioButtonCustom from 'components/common/RadioButtonCustom';
import ModuleHelpers from 'components/AlgoliaFilters/moduleHelpers';
import { ALGOLIA_FILTERS_IDS } from 'components/AlgoliaFilters/constants';
import { getDOMRectById } from 'utils/browser';

import DOCUMENT_READY_STATE_COMPLETE from './constants';
import { IActiveItem, IConnectedCategoryFiltersProps } from './model';

import './ConnectedCategoryFilters.scss';

const ConnectedCategoryFilters: FC<IConnectedCategoryFiltersProps> = ({
  refine,
  categories,
  activeCategoryId,
  customGlobalStyles,
  masterPageData,
  isMobile,
}): ReactElement => {
  const [activeItem, setActiveItem] = useState<IActiveItem | null>(null);
  const [tempActiveCategoryId, setTempActiveCategoryId] = useState<number | null>(null);

  useEffect(() => {
    if (!activeCategoryId) return;

    const activeCategory = categories.find((category) => category.id === activeCategoryId);
    if (activeCategory?.refineProhibited) return;

    refine([String(activeCategoryId)]);
  }, [activeCategoryId]);

  useEffect(() => {
    if (!activeCategoryId) return;

    const handleOnLoad = () => {
      const parentData = getDOMRectById(ALGOLIA_FILTERS_IDS.categoryFiltersList);
      const currentBtnData = getDOMRectById(String(activeCategoryId));

      if (!currentBtnData || !parentData) return;

      setActiveItem({
        id: activeCategoryId,
        left: currentBtnData.left - parentData.left - 1,
        width: currentBtnData.width,
      });
    };

    if (window.document.readyState === DOCUMENT_READY_STATE_COMPLETE) {
      handleOnLoad();

      return;
    }

    window.addEventListener('load', handleOnLoad);

    return () => {
      window.removeEventListener('load', handleOnLoad);
    };
  }, [activeCategoryId]);

  const handleBtnClick = useCallback(
    (id: number, link: string) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const parentData = getDOMRectById(ALGOLIA_FILTERS_IDS.categoryFiltersList);
      const currentBtnData = event.currentTarget.getBoundingClientRect();

      if (!parentData) return;

      setActiveItem({
        id,
        left: currentBtnData.left - parentData.left - 1,
        width: currentBtnData.width,
      });

      setTimeout(() => {
        navigate(link);
      }, 500);
    },
    [activeCategoryId]
  );

  const handleRadioBtnClick = useCallback(
    (id: number, link: string) => () => {
      setTempActiveCategoryId(activeCategoryId === id ? 0 : id);

      setTimeout(() => {
        navigate(activeCategoryId === id ? masterPageData.link : link);
      }, 500);
    },
    [activeCategoryId]
  );

  const handleUnapply = useCallback(() => {
    navigate(masterPageData.link);
  }, [masterPageData]);

  const { defaultTextColorClass, defaultBgColorClass } = ModuleHelpers.getCustomClassNames(
    customGlobalStyles
  );

  return (
    <div className="nf-category-filters">
      <div className="list" id={ALGOLIA_FILTERS_IDS.categoryFiltersList}>
        {!isMobile && activeItem ? (
          <div
            className={classNames('toggler', {
              [`${defaultTextColorClass}`]: defaultTextColorClass,
              [`${defaultBgColorClass}`]: defaultBgColorClass,
            })}
            style={{ left: activeItem.left, width: activeItem.width }}
          >
            <IconCustom icon="close_icon-2" onClick={handleUnapply} />
          </div>
        ) : null}
        {isMobile
          ? categories.map(({ id, title, relatedPageLink }) => (
              <RadioButtonCustom
                key={id}
                id={id}
                labelText={title}
                name="category"
                isChecked={
                  tempActiveCategoryId !== null
                    ? tempActiveCategoryId === id
                    : activeItem?.id === id || activeCategoryId === id
                }
                onClick={handleRadioBtnClick(id, relatedPageLink)}
                inputColor={customGlobalStyles?.defaultControlsBgColor}
              />
            ))
          : categories.map(({ id, title, relatedPageLink }) => (
              <button
                key={id}
                id={String(id)}
                type="button"
                className={classNames('item', {
                  'item-active': activeItem?.id === id,
                  [`${defaultTextColorClass}`]: defaultTextColorClass,
                  [`${defaultBgColorClass}`]: defaultBgColorClass,
                })}
                onClick={handleBtnClick(id, relatedPageLink)}
              >
                {title}
              </button>
            ))}
      </div>
    </div>
  );
};

export default connectRefinementList(ConnectedCategoryFilters);
