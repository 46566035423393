export const ALGOLIA_FILTERS_PARAMS = {
  filterSection: 'fs',
};

export const ALGOLIA_FILTERS_IDS = {
  showResultsScrollTarget: 'showResultsScrollTarget',
  categoryFiltersList: 'categoryFiltersList',
};

export const ALGOLIA_FILTERS_NUMBERS = {
  maxFilterItemsToShow: 15,
};

export const HitsPerPage = 999;

export const DefaultSearchState: AppFilters.IAlgoliaSearchState = {
  configure: {
    analytics: false,
    distinct: true,
    hitsPerPage: HitsPerPage,
  },
  page: 1,
  optionalFilters: '',
  refinementList: {},
};

export const FILTER_OPERATOR_OR: AppFilters.TFilterOperator = 'or';
